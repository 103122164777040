.logocolor{
    background-color: #080d09;
    width: 120px;
    border-radius: 19px;
    
}
.firstnav{
    background-color: aqua;
    width: 100%;
}

.secondnavbar{
    background-color:yellow;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}
.navbar-brand{
    background-color: #080d09;
    border-radius: 19px;
}
.navbar-collapse{
    
     width: 70%; 
}
.navbar{
    color: #ffffff;
    width: 100%;
     padding: 10px 50px;
}
.drawerdiv{
   
    background-color: #080d09;
 height: 100%;
 
}
.draweroveralldiv{
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}
/* <---Animation CSS---> */

 .hover-underline-animation {
   display: inline-block;
   position: relative;
   color:  #ffffff;
   text-decoration: none;
 }
 
 .hover-underline-animation:after {
   content: '';
   position: absolute;
   width: 100%;
   transform: scaleX(0);
   height: 2px;
   bottom: 0;
   left: 0;
   background-color: #ffffff;
   transform-origin: bottom right;
   transition: transform 0.25s ease-out;
 }
 
 .hover-underline-animation:hover:after {
   transform: scaleX(1);
   transform-origin: bottom left;
 }
 .hover-underline-animation:hover{
      color: #ffffff;
      
 }
@media only screen and (max-width: 991px) {
    .navbar {
        padding: 10px 0px 0px 5px;
    }
    .logocolor{
        background-color: #080d09;
        width: 80px;
        border-radius: 15px;
    }
  }
.navbar-nav{
    background-color: #121b11;
    width: 700px;
    height: 68px;
    border-radius: 19px;

border: 1px solid rgba(18, 27, 17, 1);
}

.maindiv{
    display: flex;
    justify-content: center;
}
.nav-link{
    color: whitesmoke ;
    font-weight: 700;
}
.nav-link:active{
    color: #ffffff ;
}
 .nav-link:hover{
   color:#ffffff ; 
   text-decoration: underline;
} 

.btns{
    background-color: #ffffff;
    color: black;
    border-radius: 19px;
    font-size: 16px;
    border-color: black;
    height: 35px;
    justify-content: center;
    align-items: center;
    width: 80px;
    display: flex;
    margin-top: -15px;
    cursor: pointer;
}
.btns:hover{
    background-color: black;
    color: #ffffff;
    border-color: black;
}

