.sides{
   display: flex;
   margin-top: 49px;
   width: 25%;
}
.backcolors{
    background-color: #f0f0f0;
}
.firstvr{
    height: 700px;
    margin-top: 49px; 
}
.onevr{
    height: 700px;
}
.twovr{
    height: 700px;
}
.footimg{
   height: 89px;
   width: 90%;
}
.socialmedia{
    margin-top: 150px;
    width: 350px;
}
.betweens{
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-top: 90px;
}
.copyrightletter{
    margin-top: 44px;
    margin-left: 3px;
}
.copyrightletters{
    margin-left: 3px;
    display: none;
}
.mar{ 
    margin-left: 7px;
}
.address{
    margin-left: 7px; 
}
.socialmediaicon{
    margin-left: 7px;
    width: 70%;
}
.fontsize{
    width: 35px;
    cursor: pointer;
}
.horiszontal{
    display: none;
}
.horiszontalmiddle{
    display: none;
}
.horiszontalsecondmiddle{
    display: none;
}
.horiszontalbottom{
    display: none;
}
.sitemap{
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    margin-left: 7px;
}
.tapresponsiveforsitemap{
    display: flex;
    height: 700px;
    flex-direction: column;
}
.cursorpoint{
    cursor: pointer;
}
.hiddenBlock{
    opacity: 0.5;
    cursor: not-allowed;
}
@media (min-width: 900px) and (max-width: 1280px){
    .betweens{
        width: 95%;
    }
    .footimg{
        height: 75px;
        width: 85%;
     }
    .hr{
        width: 200px;
    }
    .socialmediaicon{
        margin-left: 7px;
        width: 90%;
        margin-top: 20px;
    }
    .ourproducts{
        font-size: 14px;
        font-weight: 700;
    }
    .copyrightletter{
        margin-top: 34px;
        margin-left: 3px;
        font-size: 13px;
    }
}
@media (min-width: 1281px) and (max-width: 1377px){
    .sitemap{
        width: 120px;
    }
    .footimg{
        height: 79px;
        width: 80%; 
     }
     .copyrightandletters{
         width: 400px;
     }
}
@media (min-width: 481px) and (max-width: 899px) {
  .onevr{
    height: 236px; 
  }
  .backcolors{
      display: flex;
  }
  .betweens{
    display: flex;
    flex-direction: column; 
}
.horiszontal{
    display: block;
}
.horiszontalmiddle{
    display: block;
    margin-top: -450px;
}
.horiszontalsecondmiddle{
    display: block;
     margin-top: -1px;  
}
.horiszontalbottom{
    display:  block;
    margin-top: -11px;
}
.footimg{
    height: 69%;
    width: 49%;
    margin-left: 9px;
}
.mapsite{
    font-size: 32px;
}
.copyrightletter{
    display: none;
}
.copyrightletters{
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sitemap{
  margin-top: 10px;
}
.tapresponsiveforsitemap{
    display: flex;
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
}
.address{
    margin-top: -35px;
    display: flex;
    width: 100%;
    gap: 40px;
}
.headoffic{
    font-size: 20px;
}
.socialmediaicon{
    height: 350px;
      margin-top: -50px; 
       width: 97%;
}
.test{
     width: 60%;
}
.hrea{
    width: 60%;
}
.sides{
    width: 75%;
    height: 307px;
}
.ourproducts{
    font-size: 26px;
    width: 400px;
}
.firstvr{
    margin-top: -200px;    
    height: 808px;
}
.twovr{
    height: 403px;
    margin-top: -65px; 
}
}
/* <---Mobile Responsive ---> */
@media (min-width: 325px) and (max-width: 480px) {
    .backcolors{
        display: flex;
    }
    .betweens{
      display: flex;
      flex-direction: column;
  }
  .horiszontal{
      display: block;
  }
  .horiszontalmiddle{
      display: block;
      margin-top: -450px;
  }
  .horiszontalsecondmiddle{
      display: block;
      margin-top: -384px;
  }
  .horiszontalbottom{
      display:  block;
      margin-top: -15px;
  }
  .footimg{
      height: 59%;
      width: 49%;
      margin-left: 9px;
  }
  .mapsite{
      font-size: 32px;
  }
  .copyrightletter{
      display: none;
  }
  .copyrightletters{
      display: block;
      display: flex;
      justify-content: center;
      font-size: 15px;
  }
  .sitemap{
    margin-top: 10px;
  }
  .tapresponsiveforsitemap{
      display: flex;
      flex-direction: row;
      width: 300px;
      justify-content: space-between;
  }
  .address{
       margin-top: -27px;
       height: 424px; 
  }
  .headoffice{
      font-size: 23px;
  }
  .socialmediaicon{
      height: 300px;
  }
  .sides{
      width: 75%;
      margin-top: -15px;
      height: 349px;
  }
  .ourproducts{
      font-size: 22px;
      width: 350px;
  }
  .firstvr{
      margin-top: -183px;
  }
  .twovr{ 
      height: 374px;
  }
   .onevr{
    height: 800px; 
  }
}