.portfolionavbar {
  background-color: white;
}
.cards {
  width: 380px;
  height: 250px;
}
.margintop {
  margin-top: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.ourlatestlet{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.img {
  height: 100%;
  width: 100%;
}
.containers {
  width: 90%;
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.portfolioletter {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.portfolioplayers {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
}
.portpoliovideos {
  width: 100px;
}
.ourlatestletters {
  margin-top: 50px;
}
.portfolicardvideos {
  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;
}
/* <--- external card CSS ---> */
.card {
  width: 400px;
  height: 300px;
  background: #313131;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
  box-shadow: white;
  margin-top: 10px;
}
.img {
  height: 100%;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;
}
 .colorbut{
   margin-top: 25px;
 
 }
 .p{
   font-size: 25px;
 }
@media (min-width: 400px) and (max-width: 499px) {
  .card {
    width: 350px;
    height: 300px;
    background: #313131;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 0.2s ease-in-out;
    box-shadow: white;
    margin-top: 100px;
  }
}
@media (min-width: 300px) and (max-width: 399px) {
  .card {
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
   
  }
}