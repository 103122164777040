.navabartest{
  background-color: #faa504;
}

.ourteammain{
  background-color: #ffd392;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.Meetourteamletters{
  display: flex;
  justify-content: center;
  background-color: red;

}
.imagone{
  background-color: yellow;
  width: 200px;
  height: 400px;
  display: flex;
  justify-content: center;

}
.ourTeams{
  
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.c {
    font-size: 200px;
    width: 0.1em;
    height: 1em;
    background: linear-gradient(#faa504, #141001);
    position: absolute;
    animation: rotate_4001510 7s infinite linear;
    transform-style: preserve-3d;
  }
  
  .c .side, .c:before, .c:after {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    overflow: hidden;
    border-radius: 50%;
    right: -0.4em;
    text-align: center;
    line-height: 1;
    transform: rotateY(-90deg);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .c .tails, .c:after {
    left: -0.4em;
    transform: rotateY(90deg);
  }
  
  .c:before, .c:after {
    background: linear-gradient(#faa504, #141001);
    backface-visibility: hidden;
    transform: rotateY(90deg);
  }
  
  .c:after {
    transform: rotateY(-90deg);
  }
  
  @keyframes rotate_4001510 {
    100% {
      transform: rotateY(360deg);
    }
  }
  .svg_back {
    transform: scaleX(-1);
  }

  .c:hover{
    animation-play-state: paused; 
    cursor: pointer;
  }
  .side heads{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .span{
   font-size: 10px;
   font-weight: 1px;
   width: 1px;
  }
 
  
  
/* <--- 2nd image ---> */
.imgetwo{
  width: 200px;
  height: 400px;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-self: flex-end;

}
.a {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001);
  display: flex;
  
  align-self: flex-end;
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
}

.a .side, .a:before, .a:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.a .tails, .a:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.a:before, .a:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.a:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.a:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}


  {/* <--Sherlin Imasge--> */}

.imgethree{
  width: 200px;
  height: 400px;
  background-color:violet;
  display: flex;
  justify-content: center;

}
  
.Sherlin {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001);

  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
}

.Sherlin .side, .Sherlin:before, .Sherlin:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Sherlin .tails, .Sherlin:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Sherlin:before, .Sherlin:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Sherlin:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Sherlin:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

/* <--Nithin img Css--> */

.imgefour{
  width: 200px;
  height: 400px;
  background-color:springgreen;
  display: flex;
  justify-content: center;
}
  
.Nithin {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001);
   animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
  display: flex;
  align-self: flex-end;
}

.Nithin .side, .Nithin:before, .Nithin:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Nithin .tails, .Nithin:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Nithin:before, .Nithin:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Nithin:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Nithin:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

.firstfourimg{
  display: flex;
  background-color: black;
  width: 80%;
  justify-content: space-around;
  height: 400px;
  flex-wrap: wrap;
}


  {/* <--Ashik Imasge Css--> */}

.imgefive{
  width: 200px;
  height: 400px;
  background-color:aqua;
  display: flex;
  justify-content: center;

}
  

  
.Ashik {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001);
  display: flex;
  align-self: center;
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
}

.Ashik .side, .Ashik:before, .Ashik:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Ashik .tails, .Ashik:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Ashik:before, .Ashik:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Ashik:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Ashik:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}


 {/* <--Vishnu Imasge css--> */}

.imagesix{
  width: 200px;
  height: 400px;
  background-color:#faa504;
  display: flex;
  justify-content: center;

}

   

  
.Vishnu {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001);
  display: flex;
  align-self:flex-end;


  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
}

.Vishnu .side, .Vishnu:before, .Vishnu:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Vishnu .tails, .Vishnu:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Vishnu:before, .Vishnu:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Vishnu:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Vishnu:hover{
  animation-play-state: paused; 
  cursor: pointer;
  
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

 {/* <--Vishnuvijay Imasge Css--> */}
.imageseven{
  width: 200px;
  height: 400px;
  background-color:tomato;
  display: flex;
  justify-content: center;

}


 .Vishnuvijay {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001); 
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
}

.Vishnuvijay .side, .Vishnuvijay:before, .Vishnuvijay:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Vishnuvijay .tails, .Vishnuvijay:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Vishnuvijay:before, .Vishnuvijay:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Vishnuvijay:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Vishnuvijay:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

   {/* <--Manjuma Imasge--> */}


   .imageeight{
    width: 200px;
    height: 400px;
    background-color:#faa504;
    display: flex;
    justify-content: center;
  
  }
  
  
   .Manjuma {
    font-size: 200px;
    width: 0.1em;
    height: 1em;
    background: linear-gradient(#faa504, #141001); 
    animation: rotate_4001510 7s infinite linear;
    transform-style: preserve-3d;
    display: flex;
    align-self:flex-end;
  }
  
  .Manjuma .side, .Manjuma:before, .Manjuma:after {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    overflow: hidden;
    border-radius: 50%;
    right: -0.4em;
    text-align: center;
    line-height: 1;
    transform: rotateY(-90deg);
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .Manjuma .tails, .Manjuma:after {
    left: -0.4em;
    transform: rotateY(90deg);
  }
  
  .Manjuma:before, .Manjuma:after {
    background: linear-gradient(#faa504, #141001);
    backface-visibility: hidden;
    transform: rotateY(90deg);
  }
  
  .Manjuma:after {
    transform: rotateY(-90deg);
  }
  
  @keyframes rotate_4001510 {
    100% {
      transform: rotateY(360deg);
    }
  }
  .svg_back {
    transform: scaleX(-1);
  }
  
  .Manjuma:hover{
    animation-play-state: paused; 
    cursor: pointer;
  }
  .side heads{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .span{
   font-size: 10px;
   font-weight: 1px;
   width: 1px;
  }

    {/* <--Manjuma Imasge css over--> */}

.secondfourimg{
  background-color: sienna;
  display: flex;
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.thiredfourimg{
  background-color:chartreuse;
  display: flex;
  width: 80%;
 justify-content: space-around;
  flex-wrap: wrap;
}

{/* <--Neethu Imasge--> */}




.imagenine{
  width: 200px;
  height: 400px;
  background-color:#faa504;
  display: flex;
  justify-content: center;

}


 .Neethu {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001); 
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
  display: flex;
  align-self:center;
}

.Neethu .side, .Neethu:before, .Neethu:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Neethu .tails, .Neethu:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Neethu:before, .Neethu:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Neethu:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Neethu:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

{/* <--Akhil Imasge--> */}

.imageten{
  width: 200px;
  height: 400px;
  background-color:violet;
  display: flex;
  justify-content: center;

}


 .Akhil {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001); 
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
  display: flex;
  align-self:flex-end;
}

.Akhil .side, .Akhil:before, .Akhil:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Akhil .tails, .Akhil:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Akhil:before, .Akhil:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Akhil:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Akhil:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

{/* <--Saji Imasge css--> */}


.imageeleven{
  width: 200px;
  height: 400px;
  background-color:springgreen;
  display: flex;
  justify-content: center;

}


 .Saji {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001); 
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
  display: flex;
  align-self:flex-start;
}

.Saji .side, .Saji:before, .Saji:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Saji .tails, .Saji:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Saji:before, .Saji:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Saji:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Saji:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}


{/* <--Abhirag Imasge css--> */}



.imagetwel{
  width: 200px;
  height: 400px;
  background-color:#faa504;
  display: flex;
  justify-content: center;

}


 .Abhirag {
  font-size: 200px;
  width: 0.1em;
  height: 1em;
  background: linear-gradient(#faa504, #141001); 
  animation: rotate_4001510 7s infinite linear;
  transform-style: preserve-3d;
  display: flex;
  align-self:flex-end;
}

.Abhirag .side, .Abhirag:before, .Abhirag:after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  overflow: hidden;
  border-radius: 50%;
  right: -0.4em;
  text-align: center;
  line-height: 1;
  transform: rotateY(-90deg);
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.Abhirag .tails, .Abhirag:after {
  left: -0.4em;
  transform: rotateY(90deg);
}

.Abhirag:before, .Abhirag:after {
  background: linear-gradient(#faa504, #141001);
  backface-visibility: hidden;
  transform: rotateY(90deg);
}

.Abhirag:after {
  transform: rotateY(-90deg);
}

@keyframes rotate_4001510 {
  100% {
    transform: rotateY(360deg);
  }
}
.svg_back {
  transform: scaleX(-1);
}

.Abhirag:hover{
  animation-play-state: paused; 
  cursor: pointer;
}
.side heads{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.span{
 font-size: 10px;
 font-weight: 1px;
 width: 1px;
}

/* <-- Our Team Images Resposive Css --> */

@media (min-width: 750px) and (max-width: 1990px){
      
  .Meetourteamletters{
     margin-top: 150px;
     background-color: #faa504;
   
}
}

@media (min-width: 500px) and (max-width: 749px){


  .Meetourteamletters{
    margin-top: 100px;
    background-color: chartreuse;
  }
  .secondfourimg{
    background-color:blue ;
  
  }
  .firstfourimg{
    background-color: aquamarine;
    height: 200px;
  }
}
@media (max-width: 499px){

  .Meetourteamletters{
    margin-top: 100px;
  
}
}


/* <--first four image responsive--> */

  @media (min-width:843px) and (max-width: 999px){
  
  .firstfourimg{
   
    background-color:blue;
    width: 95%;
  }


  .secondfourimg{
    background-color: sienna;
    display: flex;
    width: 95%;
  
    /* flex-wrap: wrap; */
  }
  .thiredfourimg{
    background-color:chartreuse;
 
    width: 95%;
   
    /* flex-wrap: wrap; */
  }
  

}

@media (min-width:800px) and (max-width: 843px){

  .firstfourimg{
   
    background-color:blue;
    width: 100%;
  }
  .secondfourimg{
    background-color: sienna;

    width: 100%;
  
    /* flex-wrap: wrap; */
  }
  .thiredfourimg{
    background-color:chartreuse;
 
    width: 100%;
   
    /* flex-wrap: wrap; */
  }
  


  
}



 @media (min-width:750px) and (max-width: 799px){



  .c{
    font-size: 170px;
  }
  .a{
    font-size: 170px;
  }
  .Sherlin{
    font-size: 170px;
  }
  .Nithin{
    font-size: 170px;
  }
  .Ashik{
    font-size: 170px;
    display: flex;
    align-self:flex-start
  }
  .Vishnu{
    font-size: 170px;
  }
  .Vishnuvijay{
    font-size: 170px;
  }
  .Manjuma{
    font-size: 170px;
  }
  .Neethu{
    font-size: 170px;
    display: flex;
    align-self:flex-start
  }
  .Akhil{
    font-size: 170px;
  }
  .Saji{
    font-size: 170px;
  }
  .Abhirag{
    font-size: 170px;
  }


.imagone{

    width: 150px;
}
.imgetwo{
  width: 150px;
}
.imgethree{
  width: 150px;
}
.imgefour{
  width: 150px;
}
.imgefive{
  width: 150px;

}
.imagesix{
  width: 150px;
}
.imageseven{
  width: 150px;
}
.imageeight{
  width: 150px;
}
.imagenine{
  width: 150px;
}
.imageten{
  width: 150px;
}
.imageeleven{
  width: 150px;
} 
.imagetwel{
  width: 150px;
}
.firstfourimg{
  display: flex;
  justify-content: space-evenly;
  background-color: #faa504;
}
.secondfourimg{
  display: flex;
  justify-content: space-evenly;
}
.thiredfourimg{
  display: flex;
  justify-content: space-evenly;
}

 }

 @media (min-width:500px) and (max-width: 749px){

 
   .firstfourimg{
     display: flex;
     height: 100vh;
     justify-content: space-evenly;
     flex-wrap: wrap;
   }
   .secondfourimg{
     display: flex;
     flex-wrap: wrap;
     justify-content: space-evenly;
   
    
   }
   .thiredfourimg{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
   }

   .Ashik {
    display: flex;
    align-self: flex-start;
  }
  .Neethu{
    display: flex;
    align-self: flex-start;
  }

 }

 @media  (max-width: 499px){

  .imgetwo{
    width: 200px;
    height: 400px;
    background-color:#141001;
    display: flex;
    justify-content: center;
    align-self: flex-end;
  
  }
  .a {
 
    display: flex;
    
    align-self: flex-start;
  
  } 
   .secondfourimg{
     display: flex;
     justify-content: center;
     margin-top: 720px;
   }
   .Nithin{
    display: flex;
    align-self: flex-start;
   }
   .Ashik {
    display: flex;
    align-self: flex-start;
   }
   .Vishnu{
    display: flex;
    align-self: flex-start;
   }
   .Manjuma{
    display: flex;
    align-self: flex-start;
   }
   .imagone{
  
    height: 280px;
   }
   .imgetwo{
    height: 280px;
   }
   .imgethree{
    height: 280px;
   }
   .imgefour{
    height: 280px;
   }
   .imgefive{
    height: 280px;
   }
   .imagesix{
    height: 280px;
   }
   .imageseven{
    height: 280px;
   }
   .imageeight{
    height: 280px;
   }
   .imagenine{
    height: 280px;
   }
   .imageten{
    height: 280px;
   }
   .imageeleven{
    height: 280px;
   }
   .imagetwel{
    height: 280px;
   }
   .Neethu{
    display: flex;
    align-self: flex-start;
   }   
   .Akhil{
    display: flex;
    align-self: flex-start;
   }
   .Abhirag{
    display: flex;
    align-self: flex-start;
   } 
 }
 .ourteamholl{
   background-color: #ffd392;
 }
 .ourteamfooter{
   background-color: #faa504;
 }