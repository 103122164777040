.btn-file {
    position: relative;
    overflow: hidden;
  }
  .btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
  .jobsbox{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .mapingdiv{
    display: flex;
    flex-direction: column;
    width: 40%;
  }
  .mapingdivs{
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
  }
  /* <---Mapping button Css---> */
    {/* <--- 3D Animator  Button---> */}
  .mappbut {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%;
}
.mappbut {
  --primary-color: #111;
  --hovered-color:#07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
}
.mappbut p {
  margin-top: 5px;
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.mappbut::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.mappbut p::before {
  position: absolute;
  content: "3D Animator";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.mappbut:hover::after {
  width: 33%;
}
.mappbut:hover p::before {
  width: 100%;
}
.mappbut:hover svg {
  color: var(--hovered-color)
}
.mappbut svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}
{/* <--- 3D Animator  Button over---> */}

{/* <--- 3D Texturing Artist Button---> */}
.TexturingArtist{
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%;
}
.TexturingArtist {
  --primary-color: #111;
  --hovered-color:#07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem; 
}
.TexturingArtist p {
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.TexturingArtist::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.TexturingArtist p::before {
  position: absolute;
  content: "3D Texturing Artist";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.TexturingArtist:hover::after {
  width: 48%;
}
.TexturingArtist:hover p::before {
  width: 100%;
}
.TexturingArtist:hover svg {
  color: var(--hovered-color)
}
.TexturingArtist svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}
{/* <---  3D Rigging Artist css---> */}
.RiggingArtist{
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%; 
}
.RiggingArtist {
  --primary-color: #111;
  --hovered-color: #07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem; 
}
.RiggingArtist p {
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.RiggingArtist::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.RiggingArtist p::before {
  position: absolute;
  content: "3D Rigging Artist";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.RiggingArtist:hover::after {
  width: 48%;
}
.RiggingArtist:hover p::before {
  width: 100%;
}
.RiggingArtist:hover svg {
  color: var(--hovered-color)
}
.RiggingArtist svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}
  {/* <---  Rendering Artist CSS---> */}
.RenderingArtist{
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%;
}
.RenderingArtist {
  --primary-color: #111;
  --hovered-color: #07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
}
.RenderingArtist p {
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.RenderingArtist::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.RenderingArtist p::before {
  position: absolute;
  content: "Rendering Artist";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.RenderingArtist:hover::after {
  width: 40%;
}
.RenderingArtist:hover p::before {
  width: 100%;
}
.RenderingArtist:hover svg {
  color: var(--hovered-color)
}
.RenderingArtist svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}
 {/* <--- 3D Modeling Artist---> */} 
.ModelingArtist{
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%; 
}
.ModelingArtist{
  --primary-color: #111;
  --hovered-color: #07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
}
.ModelingArtist p {
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.ModelingArtist::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.ModelingArtist p::before {
  position: absolute;
  content: "Modeling  Artist";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.ModelingArtist:hover::after {
  width: 40%;
}
.ModelingArtist:hover p::before {
  width: 100%;
}
.ModelingArtist:hover svg {
  color: var(--hovered-color)
}
.ModelingArtist svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}
 {/* <--- VFX Artist Css---> */}
.vfxArtist{
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%;
}
.vfxArtist {
  --primary-color: #111;
  --hovered-color: #07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
}
.vfxArtist p {
  /* margin-right: 10px; */
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.vfxArtist::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.vfxArtist p::before {
  position: absolute;
  content: "VFX Artist";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.vfxArtist:hover::after {
  width: 30%;
}
.vfxArtist:hover p::before {
  width: 100%;
}
.vfxArtist:hover svg {
  color: var(--hovered-color)
}
.vfxArtist svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}
  {/* <--- Compositor Editor 2css---> */}
.CompositorEditor{
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  width: 90%;
}
.CompositorEditor {
  --primary-color: #111;
  --hovered-color: #07aa61;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 20px;
  gap: 0.5rem;
}
.CompositorEditor p {
  position: relative;
  font-size: 15px;
  color: var(--primary-color)
}
.CompositorEditor::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: 15px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}
.CompositorEditor p::before {
  position: absolute;
  content: "Compositor Editor";
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}
.CompositorEditor:hover::after {
  width: 48%;
}
.CompositorEditor:hover p::before {
  width: 100%;
}
.CompositorEditor:hover svg {
  color: var(--hovered-color) 
}
.CompositorEditor svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
  margin-top: -13px;
}