.main{
  background-image: url(../../Assets/bg.png);
   height:98vh;
  width: 100%;
 background-size: 100% 100%;
  background-repeat: 'no-repeat';
}  
/* <-- Mobile Resposive --->   */
@media only screen and (max-width: 479px)  {
  .main{
      background-image: url(../../Assets/bg.png);
       height:54vh;
      width: 100%;
     background-size: 100% 100%;
      background-repeat: 'no-repeat';
  }  
}
  .colordis{
    background-color: white;
    opacity: 30;
  }
  .aboutdiv{
    width: 40%;
  }
  .contentpara{
    text-align: justify;
   color: white;
   font-size: 20px;
  }
  .hidediv{
     display:none;
  }
 .backgroundblack{
   background-color: black;
 }
  .netcommedialetter:hover + .hidediv{
    display: block;
  }
  .aboutdiv:hover .hidediv{
    display: block;
  }
  .aboutdiv:hover .netcommedialetter{
    display: none;
  }
  .netcommedialetter{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* background-color:yellow; */
  }
 #firstimage{
   width: 100%;
   padding-bottom:10px;
 }
 #secondimage{
   width: 100%;
 }
 .gillgallcolors{
  width: 20%;
  display: none; 
}
.netcomcolors{
  display: none;
}
.crazecodecolors{
  width: 20%;
  display: none;
}
.kevincolors{
  width: 20%;
  display: none;
}
.kevincolor:hover + .kevincolors{
  display: block;
}
.kevincolor:hover{
  display: none;
}
.gillgallcolor:hover + .gillgallcolors{
    display: block;  
}
.gillgallcolor:hover {
    display:none;
}
.netcomcolor:hover + .netcomcolors{
  display: block;
}
.netcomcolor:hover{
  display: none;
}
.crazecodecolor:hover + .crazecodecolors{
   
  display: block;
}
.crazecodecolor:hover{

   display: none;
}
.animationvideos{
  width: 100%;
  color: #000000;
}

 @media (min-width: 281px) and (max-width: 767px) {

  .aboutdiv{ 
    width: 75%;
  }
  .contentpara{
   font-size: 13px;
  }
}
@media (min-width: 1051px) and (max-width: 1236px) {
     
  .contentpara{
    font-size: 15px;
   }
}
.verticalline{
  height: 123px;
  width: 4px;
}
.crazecodecolor{
   width: 20%;
}
.crazecodecolors{
  width: 20%;
}
.netcomcolor{
  width: 20%;
  height: 120px;
}
.netcomcolors{
  width: 20%;
  height: 120px;
}
.gillgallcolor{
  width: 20%;
}
.kevincolor{
  width: 20%;
 
}
.kevincolors{
  width: 20%;
}
.gillgallcolors{
  width: 20%; 
}
.borderdiv{
  width: 75%;
  cursor: pointer;
} 
@media (min-width: 900px) and (max-width: 1050px) {
  .borderdiv{
    width: 85%;
    cursor: pointer;
  } 
  .netcomcolor{
    width: 20%;
    height: 100px;
  }
}
@media screen and  (min-width: 800px)  and (max-width:900px) {
.netcomcolors{
  width: 20%;
  height: 85px;
}
.borderdiv{
  width: 95%;
  height: 140px;
}
.verticalline{
  height: 84px;
  width: 4px;
}
.netcomcolor{
  width: 20%;
  height: 90px;
}
}
@media screen and  (min-width: 601px)  and (max-width:799px) {
  .borderdiv{
    width: 96%;
    height: 140px;
  }
  .netcomcolor{
    width: 17%;
    height: 86px;
  }
  .gillgallcolor{
    width: 19%;
  }
  .crazecodecolor{
    width: 19%;
  }
  .kevincolor{
    width: 19%;
  }
  .verticalline{
    height: 84px;
    width: 4px;
  }
}
@media screen and (max-width:600px) {
  
  .borderdiv{
    flex-direction: column;
    width: 70%;
  }
  .horizontalline{
   display: block;
    width: 100px;
  }
  .verticalline{
    display: none;
  }
  .animationvideos{
    
    font-size: 21px;
  }
  .netcomcolor{
    margin-top: 5px;
    background-color: whitesmoke;
    width: 69%;
    height: 10vh;
  }
  .gillgallcolors{
    width: 69%;
    height: 10vh;
   }
  .netcomcolors{
    width: 69%;
    height: 10vh;
    margin-top: 5px;
  }
  .letterforanimationvideos{
 background-color: brown;
    color: white;
  }
  .gillgallcolor{
    margin-top: 5px;
    width: 69%;
    height: 8vh;
  }
  .gillgallcolors{
    margin-top: 5px;
    cursor: pointer;
  }
  .crazecodecolor{
     margin-top: 5px;
     margin-bottom: 5px;
     width: 69%;
     height: 10vh;
  }
  .crazecodecolors{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 69%;
    height: 10vh;
 }
 .kevincolor{
  width: 69%;
  height: 8vh;
  margin-bottom: 3px;
 }
 .gillgallcolors{
  width: 69%;
  height: 8vh;
 }
 .kevincolors{
  width: 69%;
  height: 8vh;
 }
} 
.Animatingcontentprocessing{
  color: #000000;
  font-weight:900;
  font-size: 50px;
}
.row{
  width: 100%;
}
.inner{
  overflow:hidden;
}
.inner img{
  transition: all 1.9s ease;
}
.inner:hover img {
  transform: scale(2.9);
}
.heights{
  height:380px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  flex-direction: column; 
}
@media (min-width: 537px) and (max-width: 895px) {
 
  .Animatingcontentprocessing{
    color: #000000;
    font-weight:800;
    font-size: 30px;
  }
}
@media (min-width: 310px) and (max-width: 536px) {
  .Animatingcontentprocessing{
    color: #000000;
    font-weight:700;
    font-size: 15px;
  }
}
.totalfive{
  width: 250px;
  display: flex;
  align-items: center;
  border-radius:5px;
  height: 300px;
}
.mapcarddiv{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap:"10px";
}
.mappingimages{
  width: 200px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-self: start;
}
.cardbodys{
  width: 200px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  flex-direction: column;
}
.titlecards{
 display: flex;
 flex-direction: column;
 height: 380px;
}
.cardtexts{
  font-weight: 700;
}
.cardtext{
  text-align: center;
}
/* <----ApplyNow-----> */
.container{
  background-image: url(../../Assets//fin.png);
  width: 100%;
 background-size: 100% 100%;
  background-repeat: 'no-repeat';
  margin-top: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
}
.applynow{
    margin-top: 14px;
}
/* <----Apply Now Button ------> */
button {
  width: 140px;
  height: 56px;
  overflow: hidden;
  border: none;
  color: #fff;
  background: none;
  position: relative;
  padding-bottom: 2em;
}
button > div,button > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}
button:before {
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: scaleX(0);
  transform-origin: bottom right;
  background: currentColor;
  transition: transform 0.25s ease-out;
}
button:hover:before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
button .clone > *,button .text > * {
  opacity: 1;
  font-size: 1.3rem;
  transition: 0.2s;
  margin-left: 4px;
}
button .clone > * {
  transform: translateY(60px);
}
button:hover .clone > * {
  opacity: 1;
  transform: translateY(0px);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
button:hover .text > * {
  opacity: 1;
  transform: translateY(-60px);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
button:hover .clone > :nth-child(1) {
  transition-delay: 0.15s;
}
button:hover .clone > :nth-child(2) {
  transition-delay: 0.2s;
}
button:hover .clone > :nth-child(3) {
  transition-delay: 0.25s;
}
button:hover .clone > :nth-child(4) {
  transition-delay: 0.3s;
}
/* icon style and hover */
button svg {
  width: 20px;
  right: 0;
  top: 55%;
  transform: translateY(-50%) rotate(-1deg);
  transition: 0.2s ease-out;
}
button:hover svg {
  transform: translateY(-50%) rotate(-90deg);
}
/* <--- Apply now Button Over ----> */